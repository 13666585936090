import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ScreenTitleContainer } from "./ProfileScreen";
import PlaceholderImg from "../assets/placeholder.png";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  ChevronIcon,
  CopyIcon,
  LikeIcon,
  ShareMusicIcon,
  SyncedIcon,
} from "icons";
import { Search } from "components/Search";
import { Layout } from "components/Layout";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import {
  useGetRoomQuery,
  useSharePlaylistMutation,
} from "features/auth/authApiSlice";
import { DraggableCard } from "components/DraggableCard";
import cookies from "js-cookie";
import {
  addTrackToLibrary,
  deleteTrackToLibrary,
  getAccessToken,
  getPlaylistTracks,
} from "services/spotify";
import { LoadingScreen } from "components/common/LoadingScreen";
import { AnyComponent } from "styled-components/dist/types";
import {
  getAllTracksInYoutubePlaylist,
  getAllUserYoutubePlaylists,
  GOOGLE_LOCALSTORAGE_VALUES,
} from "services/youtube";
import CopyBottomModal from "components/Playlist/CopyBottomModal";
import { BgOverlay } from "./HomeScreen";
import ProgressBottomModal from "components/Playlist/ProgressBottomModal";
import UpgradeBottomModal from "components/Playlist/UpgradeBottomModal";
import CompletedBottomModal from "components/Playlist/CompletedBottomModal";
import { getPreviewUrl, youtubeToSpotify } from "services/conversion";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  clearTotalTracks,
  totalTracks,
} from "features/tracks/tracksTotalSlice";
import { clearCopied, copied } from "features/tracks/copiedTracksSlice";
import { IYoutubePlayLists, OutputType } from "@types";
import { PlaylistType } from "types";
import { PRIMARY_STREAM_SERVICE } from "contants/primaryStreamingService";
import HomeCopyInitiate from "components/Home/CopyInitiate";
import { clearFailedTracks } from "features/tracks/failedTracksSlice";
import { clearTracks } from "features/tracks/tracksSlice";
import SelectPlaylistBottomModal from "components/ManagePlaylist/DestinationPlatformModal";
import { MusicKitContext } from "components/MusicKitController";

interface LikeButtonProps {
  isLiked: boolean;
}

// songList.items.map(song=>{
// song.track.id, song.track.name, song.track.artists[0].name, song.track.album.images[0].url
// })
type ViewListProps = {
  setShowPlaylist: React.Dispatch<React.SetStateAction<boolean>>;
  playlist: any;
};
const ViewSearchedPlayListScreen = ({
  setShowPlaylist,
  playlist
}: ViewListProps) => {
  // const user = useSelector(selectCurrentUser);
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const [songList, setSongList] = useState<any>();
  const [originalSongList, setOriginalSongList] = useState<any>();
  const [filteredSongList, setFilteredSongList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [copyModal, setcopyModal] = useState<boolean>(false);
  const location = useLocation();
  const [clickedItems, setClickedItems] = useState<PlaylistType[]>([]);
  const [copyPlaylistOngoing, setCopyPlaylistOngoing] =
    useState<boolean>(false);
  const [syncPlaylistOngoing, setSyncPlaylistOngoing] =
    useState<boolean>(false);
  const [actionCompleted, setActionCompleted] = useState<boolean>(false);
  const [playListArr, setPlayListArr] = useState<OutputType>([]);
  const totalTrackss = useSelector(totalTracks);
  const totalCopied = useSelector(copied);
  const [isFetchingPlaylist, setFetchingPlaylist] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [seeDetails, setSeeDetails] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [hideModal, setHideModal] = useState<boolean>(false);
  const [initiateCopy, setInitiateCopy] = useState<boolean | null>(null);
  const [handleCopy, setHandleCopy] = useState<boolean | null>(null);
  const [sharableUrl, setShareAbleUrl] = useState<string | undefined>(
    undefined
  );
  const [isLiked, setIsLiked] = useState(false);
  const [likedTracks, setLikedTracks] = useState<string[]>([]);
  const [currentTrackId, setCurrentTrackId] = useState("");
  const isTrackLiked = (trackId: string) => likedTracks.includes(trackId);
  const context = useContext(MusicKitContext);

  useEffect(() => {
    const storedLikedTracks = localStorage.getItem("likedTracks");
    const likedTracks = storedLikedTracks ? JSON.parse(storedLikedTracks) : [];
    setLikedTracks(likedTracks);
    if (likedTracks.includes(currentTrackId)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
  }, []);

  const toggleLike = (trackId: string) => {
    setCurrentTrackId(trackId);

    // Check if the track is already liked
    const isTrackLiked = likedTracks.includes(trackId);

    // Get current liked tracks from local storage
    const storedLikedTracks = localStorage.getItem("likedTracks");
    let updatedLikedTracks = storedLikedTracks
      ? JSON.parse(storedLikedTracks)
      : [];

    if (isTrackLiked) {
      // Unlike the track
      deleteTrackToLibrary(trackId);
      updatedLikedTracks = likedTracks.filter((id) => id !== trackId);
    } else {
      // Like the track
      addTrackToLibrary(trackId);
      updatedLikedTracks = [...likedTracks, trackId];
    }

    // Update state and localStorage
    setLikedTracks(updatedLikedTracks);
    localStorage.setItem("likedTracks", JSON.stringify(updatedLikedTracks));
  };

  const addAppleTrackToLibrary = async(trackId: string) => {
    setCurrentTrackId(trackId);

    // Check if the track is already liked
    const isTrackLiked = likedTracks.includes(trackId);

    console.log(isTrackLiked)

    // Get current liked tracks from local storage
    const storedLikedTracks = localStorage.getItem("likedTracks");
    let updatedLikedTracks = storedLikedTracks
      ? JSON.parse(storedLikedTracks)
      : [];

    if (isTrackLiked) {
      // Unlike the track
      const tracks =
      context && (await context?.addAppleTrackToLibrary(trackId));
      if(tracks.status == 202){
        toast.success("Track added to library");
        updatedLikedTracks = likedTracks.filter((id) => id !== trackId);
      }else{
        toast.error("Something went wrong!");
      }
    }
    // Update state and localStorage
    setLikedTracks(updatedLikedTracks);
    localStorage.setItem("likedTracks", JSON.stringify(updatedLikedTracks));
  };

  const appType = user?.primaryStreamingService;
//   const { playlistName } = location?.state;

  const closeModal = () => {
    setcopyModal(!copyModal);
  };

  const selectPlaylistToBeCopied = (name: string, id: any) => {
    setClickedItems([{ playlistName: name, playlistId: id }]);
    setInitiateCopy(true);
  };

  const spotifyPlayListTracks = async () => {
    const { data: tracks } = await getPlaylistTracks(playlist.id);
    tracks && setSongList(tracks.items);
    tracks && setOriginalSongList(tracks.items);
    !tracks && setSongList([]);
    setLoading(false);
    return tracks.items;
  };
  // playlistId
  const youtubePlayListTracks = async () => {
    const { tracks } = await getAllTracksInYoutubePlaylist(playlist.id);
    console.log(tracks, "---data tracks youtube");
    tracks && setSongList(tracks);
    tracks && setOriginalSongList(tracks);
    !tracks && setSongList([]);
    setLoading(false);
  };

  const applePlayListTracks = async () => {
    const tracks =
      context && (await context?.getAppleMusicPlaylistTracks(playlist.id));
    tracks && setSongList(tracks);
    tracks && setOriginalSongList(tracks);
    !tracks && setSongList([]);
    setLoading(false);
  };

  const playlistTracks = () => {
    switch (appType) {
      case "youtube":
        youtubePlayListTracks();
        break;
      case "spotify":
        spotifyPlayListTracks();
        break;
      case "apple":
        applePlayListTracks();
        break;
      case "library-playlists":
        applePlayListTracks();
        break;
      default:
        break;
    }
  };

  const handleHideModals = () => {
    setHideModal(true);
  };

  const copyMusic = async () => {
    const { status, playlists } = await getAllUserYoutubePlaylists();
  };

  const handleHideModal = () => {
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    setInitiateCopy(false);
    setHandleCopy(false);
  };

  const closeCopyPlaylistModal = () => {
    setCopyPlaylistOngoing(!copyPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
    setHideModal(false);
  };

  const closeSyncPlaylistModal = () => {
    setSyncPlaylistOngoing(!syncPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
  };

  useEffect(() => {
    if (totalCopied !== 0 && totalTrackss !== 0) {
      const percentageComplete = (totalCopied / totalTrackss) * 100;
      setProgress(percentageComplete);
    }
  }, [totalCopied, totalTrackss]);

  useEffect(() => {
    playlistTracks();
  }, [playlist]);

  function searchTracks(query: string) {
    let result = originalSongList;
    if (query && query !== "" && query.length > 2) {
      const lowerQuery = query.toLowerCase(); // Convert query to lowercase for case-insensitive search

      result = originalSongList.filter((track: any) => {
        const trackName =
          track?.track?.name?.toLowerCase() ||
          track?.attributes?.name?.toLowerCase();
        const artistName =
          track?.track?.artists[0]?.name?.toLowerCase() ||
          track?.attributes?.artistName?.toLowerCase();

        return (
          trackName.includes(lowerQuery) || artistName.includes(lowerQuery)
        );
      });
    }
    setFilteredSongList(result);
    return result;
  }

  useEffect(() => {
    if (filteredSongList && filteredSongList.length <= 0) {
      setSongList(originalSongList);
    }
    setSongList(filteredSongList);
  }, [filteredSongList]);

  const [
    createSharedPlaylist,
    { data: newSharedPlaylist, isSuccess, isLoading, error: sharedRoomError },
  ] = useSharePlaylistMutation();

  const shareSpotifyPlayListTracks = async (playlist: any) => {
    try {
      const { data: tracks } = await getPlaylistTracks(playlist?.id);
      const tracksList = tracks.items;

      const playlistDetails = await Promise.all(
        tracksList.map(async (track: any) => {
          const previewUrl = await getPreviewUrl(
            track?.track?.name,
            track?.track?.artists[0]?.name,
            context
          );

          return (
            track?.track?.name && {
              name: track?.track?.name,
              url: track?.track?.href,
              isrc: track?.track?.external_ids?.isrc,
              artist: track?.track?.artists[0]?.name,
              track_id: track?.track?.id,
              image_url: track?.track?.album?.images[0]?.url,
              preview_url: previewUrl ?? "no preview",
            }
          );
        })
      );
      const filteredPlaylistDetails = playlistDetails.filter((i) => i?.name);
      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist?.name,
        playlistId: playlist.id,
        streamingService: "spotify",
        tracks: filteredPlaylistDetails,
        playlistBannerUrl:
          playlist?.images[0]?.url ?? PlaceholderImg,
      };

      const result: any = await createSharedPlaylist(sharedPlaylistData);
      if (result && result?.data && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        setShareAbleUrl(url);
        console.log(url, "copy url---");
        // Trigger share directly
        if (navigator.share) {
          await navigator.share({
            title: "Shared Playlist",
            text: "Check out this playlist I shared!",
            url: url,
          });
        }
      }
    } catch (error) {
      console.error("Error in spotifyPlayListTracks:", error);
    }
  };

  const shareYoutubePlayListTracks = async (playlist: any) => {
    const { tracks } = await getAllTracksInYoutubePlaylist(playlist?.id);

    if (tracks) {
      const playlistDetails = await Promise.all(
        tracks.map(async (track: any) => {
          const previewUrl = await getPreviewUrl(
            track?.songTitle,
            track?.author,
            context
          );

          return {
            name: track?.songTitle,
            artist: track?.author,
            track_id: track?.id,
            image_url: track?.image,
            preview_url: previewUrl ?? "no preview",
          };
        })
      );

      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist?.snippet?.title,
        playlistId: playlist?.id,
        streamingService: "youtube",
        tracks: playlistDetails,
        playlistBannerUrl:
          playlist?.snippet?.thumbnails?.default?.url ?? PlaceholderImg,
      };

      const result = await createSharedPlaylist(sharedPlaylistData);
      if ("data" in result && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        setShareAbleUrl(url);
        if (navigator.share) {
          await navigator.share({
            title: "Shared Playlist",
            text: "Check out this playlist I shared!",
            url: url,
          });
        }
      }
    }
    return null;
  };

  const shareApplePlayListTracks = async (playlist: any) => {
    const tracks =
      context && (await context?.getAppleMusicPlaylistTracks(playlist.id));
    if (tracks) {
      const playlistDetails = await Promise.all(
        tracks.map(async (track: any) => {
          // const previewUrl = await getPreviewUrl(
          //   track?.attributes?.albumName,
          //   track?.attributes?.artistName,
          //   context
          // );

          return (
            track?.attributes?.albumName && {
              name: track?.attributes?.albumName,
              artist: track?.attributes?.artistName,
              track_id: track?.id,
              image_url: track?.attributes?.artwork?.url,
            }
          );
        })
      );
      const filteredPlaylistDetails = playlistDetails.filter((i) => i?.name);
      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist?.attributes?.name,
        playlistId: playlist?.id,
        streamingService: "apple",
        tracks: filteredPlaylistDetails,
        playlistBannerUrl:
          playlist?.attributes?.artwork?.url ?? PlaceholderImg,
      };

      const result: any = await createSharedPlaylist(sharedPlaylistData);
      if (result && result?.data && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        if (navigator.share) {
          await navigator.share({
            title: "Shared Playlist",
            text: "Check out this playlist I shared!",
            url: url,
          });
        }
        return url; // Return the shareable URL
      }
    }
    return null;
  };

  const sharePlaylists = (playlist: any) => {
    switch (appType) {
      case "youtube":
        shareYoutubePlayListTracks(playlist);
        break;
      case "spotify":
        shareSpotifyPlayListTracks(playlist);
        break;
      case "apple":
        shareApplePlayListTracks(playlist);
        break;
      case "library-playlists":
        shareApplePlayListTracks(playlist);
        break;
      default:
        break;
    }
  };

  const addATrackToLibrary = (trackId: any) => {
    console.log(trackId, "trackId")
    switch (appType) {
      case "spotify":
        toggleLike(trackId);
        break;
      case "apple":
        addAppleTrackToLibrary(trackId);
        break;
      case "library-playlists":
        addAppleTrackToLibrary(trackId);
        break;
      default:
        break;
    }
  };
 console.log(songList, "song list")
  if (loading) return <LoadingScreen />;
  return (
    <>
      <ViewPlaylistScreenContainer>
        <ScreenTitleContainer>
          <span onClick={() => setShowPlaylist(false)}>
            <ChevronIcon />
          </span>

          <Search onChange={searchTracks} placeholder="Search playlist" />
        </ScreenTitleContainer>
        <CurrentSongCard>
          <img
            src={playlist?.snippet?.thumbnails?.default?.url ?? playlist?.attributes?.artwork?.url ?? (Array.isArray(playlist?.images) ? playlist.images[0]?.url : undefined)}
            alt="track cover"
          />
          <p>{playlist?.snippet?.title ?? playlist?.name ?? playlist?.attributes?.name }</p>
          <span>{playlist?.snippet?.title ?? playlist?.name ?? playlist?.attributes?.name } - {songList?.length} tracks</span>
        </CurrentSongCard>

        <ListenerOptionsCard>
          <div
            onClick={() => selectPlaylistToBeCopied(playlist?.snippet?.title ?? playlist?.name ?? playlist?.attributes?.name, playlist?.id )}
          >
            <CopyIcon />
            <p>Copy</p>
          </div>

          <div onClick={() => sharePlaylists(playlist)}>
            <ShareMusicIcon />
            <p>Share</p>
          </div>
        </ListenerOptionsCard>

        {!loading && (
          <SongStackContainer>
            {songList &&
              songList?.length > 0 &&
              songList?.map((item: any) => {
                return (
                  <div key={item?.track?.id ?? item.id ?? item?.attributes?.playParams?.id}>
                    <div>
                      <img
                        src={
                          item.image ??
                          item?.track?.album?.images[0]?.url ??
                          PlaceholderImg ??
                          item?.attributes?.artwork?.url
                        }
                        alt="track cover"
                      />
                      <div>
                        <p>
                          {item?.track?.name ??
                            item.songTitle ??
                            item?.attributes?.name}
                        </p>
                        <span>
                          {item?.track?.artists[0]?.name ??
                            item.author ??
                            item?.attributes?.artistName}
                        </span>
                      </div>
                    </div>
                    {/* <div
                    style={{ width: 40 }}
                    onClick={
                      item?.preview_url
                        ? () => toggleAudioPlayback(item?.preview_url)
                        : () => null
                    }
                  >
                    {item?.preview_url &&
                    item?.preview_url !== "no preview" ? (
                      <>
                        {currentAudio &&
                        currentAudio.src === item?.preview_url &&
                        !currentAudio.paused &&
                        audioChanged === "play" ? (
                          <PauseIcon />
                        ) : (
                          <PlayIcon />
                        )}
                      </>
                    ) : (
                      <PlayColorIcon color="#ccc" />
                    )}
                  </div> */}
                    {appType != "youtube" && (
                      <LikeButton
                        onClick={() => addATrackToLibrary(item?.track?.id ?? item?.attributes?.playParams?.id)}
                        isLiked={isTrackLiked(item?.track?.id ?? item?.attributes?.playParams?.id)}
                      >
                        {isTrackLiked(item?.track?.id ?? item?.attributes?.playParams?.id) ? (
                          <LikeIcon />
                        ) : (
                          <LikeIcon />
                        )}
                      </LikeButton>
                    )}
                  </div>
                );
              })}
          </SongStackContainer>
        )}

        {initiateCopy && (
          <>
            <HomeCopyInitiate
              closeModal={() => handleHideModal()}
              callBack={setHandleCopy}
            />
            <BgOverlay />
          </>
        )}

        {handleCopy && !actionCompleted && (
          <>
            <SelectPlaylistBottomModal
              clickedItems={clickedItems}
              setClickedItems={setClickedItems}
              onGoingState={setCopyPlaylistOngoing}
              action="Copy"
              closeModal={handleHideModal}
              playListArr={playListArr}
              actionCompleted={setActionCompleted}
            />
            <BgOverlay />
          </>
        )}

        {(syncPlaylistOngoing || copyPlaylistOngoing) && !hideModal && (
          <>
            <ProgressBottomModal
              title={
                copyPlaylistOngoing
                  ? "Copy in progress, please wait..."
                  : "Sync in progress..."
              }
              bodyText={
                copyPlaylistOngoing
                  ? `Copied ${totalCopied} of ${totalTrackss} songs`
                  : "Syncing 10 of 100 songs to your playlist"
              }
              closeModal={
                syncPlaylistOngoing
                  ? closeSyncPlaylistModal
                  : closeCopyPlaylistModal
              }
              actionBtn="Hide"
              callBack={handleHideModals}
              exitBtn="Cancel"
              icontype="copy"
              progress={progress}
            />
            <BgOverlay />
          </>
        )}

        {actionCompleted && (
          <>
            <CompletedBottomModal
              title="We are done copying your tracks 🤩"
              numberOfTracks={totalTrackss}
              totalNumOfCopiedTracks={totalCopied}
              seeDetails={seeDetails}
              setSeeDetails={setSeeDetails}
              closeModal={() => {
                setActionCompleted(false);
                dispatch(clearCopied());
                dispatch(clearFailedTracks());
                dispatch(clearTotalTracks());
                dispatch(clearTracks());
              }}
            />
            <BgOverlay />
          </>
        )}
      </ViewPlaylistScreenContainer>
      <Toaster />
    </>
  );
};

export default ViewSearchedPlayListScreen;

const ViewPlaylistScreenContainer = styled.div``;

const SongStackContainer = styled.div`
  margin-top: 20px;
  // overflow: auto;
  padding-bottom: 80px;


  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    margin: 5px 0;
    padding: 4px 6px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 0px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        border: 0px;
        p {
          font-size: 12px;
          color: #1f1f1f;
          font-weight: 700;
        }
        span {
          font-size: 10px;
          color: #8c8c8c;
          font-weight: 500;
          display: block;
        }
      }
    }
  }
`;

const CurrentSongCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;

  img {
    width: 150px;
    height: 150px;
    border-radius: 22px;
  }
  p {
    font-size: 16px;
    color: #1f1f1f;
    font-weight: 700;
    margin: 5px auto;
  }
  span {
    font-size: 12px;
    color: #8c8c8c;
    display: block;
    font-weight: 500;
  }
`;

const ListenerOptionsCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px;

    p {
      font-size: 12px;
      color: #1f1f1f;
      font-weight: 400;
      padding-left: 8px;
    }
  }
`;

const LikeButton = styled.div<LikeButtonProps>`
  cursor: pointer;
  transition: transform 0.2s ease;

  &:active {
    transform: scale(1.2);
  }

  svg {
    fill: ${({ isLiked }) => (isLiked ? "#008135" : "tansparent")};
    stroke: #008135;
    transition: fill 0.2s ease;
    width: 30px;
    height: 34px;
  }
`;
