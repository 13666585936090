import axios from "axios";
import { setCopied } from "features/tracks/copiedTracksSlice";
import toast from "react-hot-toast";
import { fetchData } from "utils";

// Map for localStorage keys
export const LOCALSTORAGE_KEYS = {
  accessToken: "spotify_access_token",
  refreshToken: "spotify_refresh_token",
  expireTime: "spotify_token_expire_time",
  timestamp: "spotify_token_timestamp",
};

// Map to retrieve localStorage values
export const LOCALSTORAGE_VALUES = {
  accessToken: window.localStorage.getItem(LOCALSTORAGE_KEYS.accessToken),
  refreshToken: window.localStorage.getItem(LOCALSTORAGE_KEYS.refreshToken),
  expireTime: window.localStorage.getItem(LOCALSTORAGE_KEYS.expireTime),
  timestamp: window.localStorage.getItem(LOCALSTORAGE_KEYS.timestamp),
};

/**
 * Clear out all localStorage items we've set and reload the page
 * @returns {void}
 */
export const logout = () => {
  // Clear all localStorage items
  for (const property in LOCALSTORAGE_KEYS) {
    localStorage.removeItem(LOCALSTORAGE_KEYS[property]);
  }
  // Navigate to homepage
  window.location = window.location.origin;
};

/**
 * Use the refresh token in localStorage to hit the /refresh_token endpoint
 * in our Node app, then update values in localStorage with data from response.
 * @returns {void}
 */
export const refreshToken = async () => {
  try {
    // Logout if there's no refresh token stored or we've managed to get into a reload infinite loop
    // if (
    //   !LOCALSTORAGE_VALUES.refreshToken ||
    //   LOCALSTORAGE_VALUES.refreshToken === null ||
    //   Date.now() - Number(LOCALSTORAGE_VALUES.timestamp) / 1000 < 1000
    // ) {
    //   console.error("No refresh token available");
    //   logout();
    // }

    // Use `/refresh_token` endpoint from our Node app
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/auth/refresh_token?refresh_token=${LOCALSTORAGE_VALUES.refreshToken}`
      // `${process.env.REACT_APP_BACKEND_URL}/auth/refresh`,
      // { withCredentials: true }
    );

    // Update localStorage values
    localStorage.setItem(LOCALSTORAGE_KEYS.accessToken, data.access_token);

    localStorage.setItem(LOCALSTORAGE_KEYS.timestamp, Date.now());

    // Reload the page for localStorage updates to be reflected
    window.location.reload(true);
  } catch (e) {
    // console.error(e);
    // window.location.href = '/home'
  }
};

/**
 * Checks if the amount of time that has elapsed between the timestamp in localStorage
 * and now is greater than the expiration time of 3600 seconds (1 hour).
 * @returns {boolean} Whether or not the access token in localStorage has expired
 */
export const hasTokenExpired = () => {
  const { accessToken, timestamp, expireTime } = LOCALSTORAGE_VALUES;
  if (!accessToken || !timestamp) {
    return false;
  }
  const millisecondsElapsed = Date.now() - Number(timestamp);
  return millisecondsElapsed / 1000 > Number(expireTime);
};

/**
 * Handles logic for retrieving the Spotify access token from localStorage
 * or URL query params
 * @returns {string} A Spotify access token
 */
export const getAccessToken = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const queryParams = {
    [LOCALSTORAGE_KEYS.accessToken]: urlParams.get("access_token"),
    [LOCALSTORAGE_KEYS.refreshToken]: urlParams.get("refresh_token"),
    [LOCALSTORAGE_KEYS.expireTime]: urlParams.get("expires_in"),
  };

  // const errorConnecting = urlParams.get("errorConnect");

  // if(errorConnecting){
  //   toast.error("Error connecting to spotify!");
  // }

  const hasError = urlParams.get("error");

  // If there's an error OR the token in localStorage has expired, refresh the token
  if (
    hasError ||
    hasTokenExpired() ||
    LOCALSTORAGE_VALUES.accessToken === null
  ) {
    refreshToken();
  }

  // If there is a valid access token in localStorage, use that
  if (
    LOCALSTORAGE_VALUES.accessToken &&
    LOCALSTORAGE_VALUES.accessToken !== "undefined"
  ) {
    return LOCALSTORAGE_VALUES.accessToken;
  }

  // If there is a token in the URL query params, user is logging in for the first time
  if (queryParams[LOCALSTORAGE_KEYS.accessToken]) {
    // Store the query params in localStorage
    for (const property in queryParams) {
      localStorage.setItem(property, queryParams[property]);
    }
    // Set timestamp
    localStorage.setItem(LOCALSTORAGE_KEYS.timestamp, Date.now());
    // Return access token from query params
    return queryParams[LOCALSTORAGE_KEYS.accessToken];
  }

  // We should never get here!
  return false;
};

export const accessToken = getAccessToken();
console.log(accessToken, "token spotify");
/**
 * Axios global request headers
 * https://github.com/axios/axios#global-axios-defaults
 */
axios.baseURL = "https://api.spotify.com/v1";
axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
axios.defaults.headers["Content-Type"] = "application/json";

const baseUrl = "https://api.spotify.com/v1";
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${accessToken}`,
};
// fetchData
/**
 * Get Current User's Profile
 * https://developer.spotify.com/documentation/web-api/reference/#endpoint-get-current-users-profile
 * @returns {Promise}
 */
// export const getCurrentUserProfile = () => axios.get("/me");
export const getCurrentUserProfile = () =>
  fetchData(`${baseUrl}/me`, "get", headers);

/**
 * Get a List of Current User's Playlists
 * https://developer.spotify.com/documentation/web-api/reference/#endpoint-get-a-list-of-current-users-playlists
 * @returns {Promise}
 */
export const getCurrentUserPlaylists = async (limit = 20) => {
  try {
    const response = await fetchData(`${baseUrl}/me/playlists`, "get", headers);
    return response;
  } catch (error) {
    console.error(`Error fetching user playlists: ${error}`);
    return {
      status: error?.response?.status || 401,
      message: error?.response?.data?.error?.message,
    };
  }
};

// export const getCurrentUserPlaylists = async (limit = 20) => {
//   // return await axios.get(`/me/playlists?limit=${limit}`);
//   // return await axios.get(`/me/playlists`);
//   return await fetchData(`${baseUrl}/me/playlists`, "get", headers);
// };

/**
 * Get a User's Top Artists
 * https://developer.spotify.com/documentation/web-api/reference/#endpoint-get-users-top-artists-and-tracks
 * @param {string} time_range - 'short_term' (last 4 weeks) 'medium_term' (last 6 months) or 'long_term' (calculated from several years of data and including all new data as it becomes available). Defaults to 'short_term'
 * @returns {Promise}
 */
export const getTopArtists = async (time_range = "short_term") => {
  return await axios.get(`/me/top/artists?time_range=${time_range}`);
};

/**
 * Get a User's Top Tracks
 * https://developer.spotify.com/documentation/web-api/reference/#endpoint-get-users-top-artists-and-tracks
 * @param {string} time_range - 'short_term' (last 4 weeks) 'medium_term' (last 6 months) or 'long_term' (calculated from several years of data and including all new data as it becomes available). Defaults to 'short_term'
 * @returns {Promise}
 */
export const getTopTracks = async (time_range = "short_term") => {
  return await axios.get(`/me/top/tracks?time_range=${time_range}`);
};

/**
 * Get a Playlist
 * https://developer.spotify.com/documentation/web-api/reference/#endpoint-get-playlist
 * @param {string} playlist_id - The Spotify ID for the playlist.
 * @returns {Promise}
 */
export const getPlaylistById = async (playlist_id) => {
  return await axios.get(`/playlists/${playlist_id}`);
};

/**
 * Get Audio Features for Several Tracks
 * https://developer.spotify.com/documentation/web-api/reference/#endpoint-get-several-audio-features
 * @param {string} ids - A comma-separated list of the Spotify IDs for the tracks
 * @returns {Promise}
 */
export const getAudioFeaturesForTracks = async (ids) => {
  return await axios.get(`/audio-features?ids=${ids}`);
};

export const removeSpotifySDK = () => {
  // Get the script element
  const script = document.querySelector(
    'script[src="https://sdk.scdn.co/spotify-player.js"]'
  );

  // Remove the script element
  if (script) {
    script.parentNode.removeChild(script);
  }

  // Remove the Spotify Web Playback SDK Ready event handler
  window.onSpotifyWebPlaybackSDKReady = null;
};

// export const getPlaylistTracks = async (playlist_id) => {
//   // console.log(playlist_id, 'playlist_id')
//   return await fetchData(
//     `${baseUrl}/playlists/${playlist_id}/tracks`,
//     "get",
//     headers
//   );
// };
export const getPlaylistTracks = async (playlist_id) => {
  let offset = 0;
  let tracks = [];
  while (true) {
    const response = await fetchData(
      `${baseUrl}/playlists/${playlist_id}/tracks?offset=${offset}`,
      "get",
      headers
    );
    tracks = [...tracks, ...response.data.items];
    if (response.data.items.length < 100) {
      break;
    }
    offset += 100;
  }
  return { data: { items: tracks } };
};

export const addTrackToLibrary = async (trackId) => {
  const url = `https://api.spotify.com/v1/me/tracks?ids=${trackId}`;

  const response = await fetch(url, {
      method: 'PUT',
      headers
  });

  if (response.ok) {
      toast.success("Track added to your library sucessfully!")
  } else {
      toast.error('Failed to add track to library');
  }
};

export const deleteTrackToLibrary = async (trackId) => {
  const url = `https://api.spotify.com/v1/me/tracks?ids=${trackId}`;

  const response = await fetch(url, {
      method: 'DELETE',
      headers
  });

  if (response.ok) {
      toast.success("Track removed from your library sucessfully!")
  } else {
      toast.error('Failed to add track to library');
  }
};

export const fetchUserTracks = async () => {
  const url = `https://api.spotify.com/v1/me/tracks`;

  let allTracks = [];
  let nextUrl = url;

  while (nextUrl) {
    const response = await fetch(nextUrl, {
      method: 'GET',
      headers,
    });

    if (response.ok) {
      const data = await response.json();
      allTracks = [...allTracks, ...data.items.map((item) => item.track)];
      nextUrl = data.next; // Assign the next URL for the next iteration
    } else {
      throw new Error('Failed to fetch tracks');
    }
  }

  console.log(allTracks);
  return allTracks;
};

export const createPlaylistTracks = async (playlist_name, items, playlistImage, dispatch) => {
  // console.log("hi herere", playlist_name, items);
  dispatch(setCopied(items.length));
  // dispatch(setCopied(successfulTracks))
  const profile = await getCurrentUserProfile();
  const {
    data: { id: newPlaylistId },
  } = await fetchData(
    `${baseUrl}/users/${profile?.data?.id}/playlists`,
    "post",
    headers,
    {
      name: playlist_name,
    }
  );
  const trackUris = items?.map((item) => item.track.uri);

  const resp = await fetchData(
    `${baseUrl}/playlists/${newPlaylistId}/tracks`,
    "post",
    headers,
    {
      uris: trackUris,
    }
  );

  // if (playlistImage) {
  //   const imageBlob = await fetchImageBlob(playlistImage); // Fetch image as Blob
  //   const imageBuffer = await getBase64Image(imageBlob); // Convert Blob to Base64
  //   await axios.put(
  //     `${baseUrl}/playlists/${newPlaylistId}/images`,
  //     imageBuffer,
  //     {
  //       headers: {
  //         ...headers,
  //         'Content-Type': 'image/jpeg',
  //       },
  //     }
  //   );
  // }
  return resp;
};


const fetchImageBlob = async (url) => {
  const response = await fetch(url);
  return response.blob();
};

// Function to convert Blob to Base64
const getBase64Image = async (blob) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]); // Remove the data URL prefix
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};


// export const getUserPlaylistAsJson = async () => {
//   const playlists = await getCurrentUserPlaylists();

//   const fetchTracksPromises = playlists.data.items.map(async (playlistItem) => {
//     const tracks = await getPlaylistTracks(playlistItem.id);
//     return {
//       playlistName: playlistItem.name,
//       playlistID: playlistItem.id,
//       tracks: tracks.data.items,
//     };
//   });

//   const playlistsWithTracks = await Promise.all(fetchTracksPromises);
//   console.log(playlistsWithTracks, "playlistsWithTracks");
//   return playlistsWithTracks;
// };

export const getUserPlaylistAsJson = async () => {
  const playlists = await getCurrentUserPlaylists();

  const fetchTracksPromises = playlists.data.items.map(async (playlistItem) => {
    const tracks = await getPlaylistTracks(playlistItem.id);
    return {
      playlistName: playlistItem.name,
      playlistID: playlistItem.id,
      tracks: tracks.data.items,
    };
  });

  const playlistsWithTracks = await Promise.all(fetchTracksPromises);

  // Create a blob from the JSON data
  const blob = new Blob([JSON.stringify(playlistsWithTracks, null, 2)], {
    type: "application/json",
  });

  // Create a link element
  const a = document.createElement("a");

  // Set the link's href to the blob's URL
  a.href = URL.createObjectURL(blob);

  // Set the download attribute to the desired file name
  a.download = "playlistsWithTracks.json";

  // Append the link to the body
  document.body.appendChild(a);

  // Simulate a click on the link
  a.click();

  // Remove the link from the body
  document.body.removeChild(a);

  return playlistsWithTracks;
};


export const searchForTrackInSpotify = async (trackName, artistName, isrc) => {
  let track;
  for (let i = 0; i < 3; i++) {
    const response = await fetchData(
      `${baseUrl}/search?q=track:${trackName}%20artist:${artistName}&type=track&limit=50&offset=${i * 50}`,
      "get",
      headers
    );

    // Filter the tracks based on the isrc
    track = isrc
      ? response.data.tracks.items.find(
          (track) => track.external_ids.isrc === isrc
        )
      : response.data.tracks.items.find(
          (track) =>
            track.artists[0].name.toLowerCase() === artistName.toLowerCase() &&
            track.name.toLowerCase() === trackName.toLowerCase()
        ) ??
        response.data.tracks.items.find(
          (track) =>
            track.artists[0].name.includes(artistName) &&
            track.name.includes(trackName)
        ) ??
        response.data.tracks.items.find(
          (track) =>
            track.artists[0].name.includes(artistName) &&
            track.name.includes(trackName.split(" ")[0])
        );
    const filteredTracks = response.data.tracks.items.filter((track) =>
      track?.name.includes(trackName)
    );
    // If a song is found, break the loop
    if (track) {
      break;
    } else if (!track && filteredTracks.length > 0) {
      // Log the filtered tracks
      console.log(filteredTracks, "filteredTracks" + trackName);

      // Use the first track from the filtered tracks, if any
      track = filteredTracks[0];
      break;
    }
  }

  return track || null;
};
