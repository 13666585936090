import { Layout } from "components/Layout";
import React, { useContext, useEffect, useState } from "react";
import { ScreenWrapper } from "./SubscriptionScreen";
import {
  getAllTracksInYoutubePlaylist,
  getAllUserYoutubePlaylists,
  refreshGoogleToken,
} from "services/youtube";
import { BackIcon } from "icons/BackIcon";
import { ScreenTitleContainer } from "./ProfileScreen";
import { ScreenTitle } from "components/ScreenTitle";
import styled from "styled-components";
import { CopyActive, SyncActive } from "icons";
import SelectPlaylistBottomModal from "components/ManagePlaylist/DestinationPlatformModal";
import { BgOverlay } from "./HomeScreen";
import ProgressBottomModal from "components/Playlist/ProgressBottomModal";
import CompletedBottomModal from "components/Playlist/CompletedBottomModal";
import { PRIMARY_STREAM_SERVICE } from "contants/primaryStreamingService";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import cookies from "js-cookie";
import { transformManagePlaylistArray } from "utils";
import { OutputType } from "@types";
import { PlaylistType } from "types";
import { clearTracks, tracks } from "features/tracks/tracksSlice";
import { clearCopied, copied } from "features/tracks/copiedTracksSlice";
import {
  LOCALSTORAGE_VALUES,
  getCurrentUserPlaylists,
  getPlaylistTracks,
  hasTokenExpired,
  refreshToken,
} from "services/spotify";
import {
  useRefreshMutation,
  useSharePlaylistMutation,
} from "features/auth/authApiSlice";
import {
  clearTotalTracks,
  totalTracks,
} from "features/tracks/tracksTotalSlice";
import { useDispatch } from "react-redux";
import { clearFailedTracks } from "features/tracks/failedTracksSlice";
import { MusicKitContext } from "components/MusicKitController";
import "react-loading-skeleton/dist/skeleton.css";
import "remixicon/fonts/remixicon.css";
import SelectPlaylist from "components/ManagePlaylist/SelectPlaylist";
import toast from "react-hot-toast";
import { getPreviewUrl } from "services/conversion";
import PlaceholderImg from "../assets/placeholder.png";

const ManagePlayListScreen = () => {
  const [refresh] = useRefreshMutation();
  const context = useContext(MusicKitContext);
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const dispatch = useDispatch();
  const [clickedItems, setClickedItems] = useState<PlaylistType[]>([]);
  const [copyModal, setcopyModal] = useState<boolean>(false);
  const [syncModal, setSyncModal] = useState<boolean>(false);
  const [copyPlaylistOngoing, setCopyPlaylistOngoing] =
    useState<boolean>(false);
  const [syncPlaylistOngoing, setSyncPlaylistOngoing] =
    useState<boolean>(false);
  const [actionCompleted, setActionCompleted] = useState<boolean>(false);
  const [playListArr, setPlayListArr] = useState<OutputType>([]);
  const totalTrackss = useSelector(totalTracks);
  const totalCopied = useSelector(copied);
  const [isFetchingPlaylist, setFetchingPlaylist] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [seeDetails, setSeeDetails] = useState<boolean>(true);
  const [hideModal, setHideModal] = useState<boolean>(false);
  const [sharableUrl, setShareAbleUrl] = useState<string | undefined>(
    undefined
  );
  const [
    createSharedPlaylist,
    { data: newSharedPlaylist, isSuccess, isLoading, error: sharedRoomError },
  ] = useSharePlaylistMutation();

  const appType = user?.primaryStreamingService;

  const handleHideModal = () => {
    setHideModal(true);
  };
  const handleCloseModal = () => {
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());

    if (copyModal) {
      setcopyModal(false);
    } else {
      setSyncModal(false);
    }
    // copyModal ? () => setcopyModal(false) : () => setSyncModal(false)
  };

  const copyPlaylist = () => {
    if (clickedItems.length > 0) {
      setcopyModal(true);
    } else {
      toast.error("Select at least a playlist");
    }
  };

  const closeSyncModal = () => {
    setSyncModal(!syncModal);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
  };

  const closeCopyPlaylistModal = () => {
    setCopyPlaylistOngoing(!copyPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
    setHideModal(false);
  };

  const closeSyncPlaylistModal = () => {
    setSyncPlaylistOngoing(!syncPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
  };

  const getCurrentStreamingPlaylist = async () => {
    if (
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      const { status, playlists } = await getAllUserYoutubePlaylists();
      console.log(playlists);
      if (status === "success") {
        setFetchingPlaylist(false);
        setPlayListArr(
          status === "success" ? transformManagePlaylistArray(playlists) : []
        );
      } else {
        setFetchingPlaylist(false);
        refreshGoogleToken(userr.googleRefreshToken);
      }
    }

    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.APPLE_MUSIC) {
      const data = context && (await context.getPlaylists());
      // console.log(data.data, "apple playlist section");
      if (data && data?.data && data?.data?.length > 0) {
        setFetchingPlaylist(false);
        setPlayListArr(transformManagePlaylistArray(data?.data) ?? []);
      }
    }
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.SPOTIFY) {
      const { status, data } = await getCurrentUserPlaylists();
      console.log(data?.items, "spotfuty section");
      if (status === 200) {
        setFetchingPlaylist(false);
        setPlayListArr(
          status === 200 ? transformManagePlaylistArray(data.items) : []
        );
      } else {
        setFetchingPlaylist(false);
        await refresh("");
        if (LOCALSTORAGE_VALUES.refreshToken && hasTokenExpired()) {
          refreshToken();
          console.log("getting new spotify token....");
        }
      }
    }
  };
  useEffect(() => {
    getCurrentStreamingPlaylist();
  }, []);

  useEffect(() => {
    if (totalCopied !== 0 && totalTrackss !== 0) {
      const percentageComplete = (totalCopied / totalTrackss) * 100;
      setProgress(percentageComplete);
    }
    console.log(
      totalCopied,
      totalTrackss,
      (totalCopied / totalTrackss) * 100,
      "totalCopied, totalTrackss,percentageComplete"
    );
  }, [totalCopied, totalTrackss]);

  const shareSpotifyPlayListTracks = async (playlist: any) => {
    const allTracks: any = [];
    try {
      for (const item of playlist) {
        const { data: tracks } = await getPlaylistTracks(item?.playlistId);
        const tracksList = tracks.items;

        const playlistDetails = await Promise.all(
          tracksList.map(async (track: any) => {
            return (
              track?.track?.name && {
                name: track?.track?.name,
                url: track?.track?.href,
                isrc: track?.track?.external_ids?.isrc,
                artist: track?.track?.artists[0]?.name,
                track_id: track?.track?.id,
                image_url: track?.track?.album?.images[0]?.url,
                // preview_url: previewUrl ?? "no preview",
              }
            );
          })
        );
        const filteredPlaylistDetails = playlistDetails.filter((i) => i?.name);
        allTracks.push(...allTracks, ...filteredPlaylistDetails);
      }
      console.log(allTracks);
      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist[0]?.playlistName,
        playlistId: playlist[0]?.playlistId,
        streamingService: "spotify",
        tracks: allTracks,
        playlistBannerUrl: allTracks[0].image_url ?? PlaceholderImg,
      };
      const result: any = await createSharedPlaylist(sharedPlaylistData);
      if (result && result?.data && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        setShareAbleUrl(url);
        console.log(url, "copy url---");
        // Trigger share directly
        if (navigator.share) {
          await navigator.share({
            title: "Shared Playlist",
            text: "Check out this playlist I shared!",
            url: url,
          });
        }
      }
    } catch (error) {
      console.error("Error in spotifyPlayListTracks:", error);
    }
  };

  const shareYoutubePlayListTracks = async (playlist: any) => {
    const allTracks: any = [];
    for (const item of playlist) {
      const { tracks } = await getAllTracksInYoutubePlaylist(item?.playlistId);
      if (tracks && tracks.length > 0) {
        console.log(tracks);
        allTracks.push(...allTracks, ...tracks);
      }
    }

    if (allTracks) {
      const playlistDetails = await Promise.all(
        allTracks.map(async (track: any) => {
          // const previewUrl = await getPreviewUrl(
          //   track?.songTitle,
          //   track?.author,
          //   context
          // );

          return {
            name: track?.songTitle,
            artist: track?.author,
            track_id: track?.id,
            image_url: track?.image,
            // preview_url: previewUrl  "no preview",
          };
        })
      );
      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist[0]?.playlistName,
        playlistId: playlist[0]?.playlistId,
        streamingService: "youtube",
        tracks: playlistDetails,
        playlistBannerUrl: allTracks[0]?.image ?? PlaceholderImg,
      };
      const result = await createSharedPlaylist(sharedPlaylistData);
      if ("data" in result && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        setShareAbleUrl(url);
        if (navigator.share) {
          await navigator.share({
            title: "Shared Playlist",
            text: "Check out this playlist I shared!",
            url: url,
          });
        }
      }
    }
    return null;
  };

  const shareApplePlayListTracks = async (playlist: any) => {
    const allTracks: any = [];

    for (const item of playlist) {
      const tracks =
        context &&
        (await context?.getAppleMusicPlaylistTracks(item.playlistId));

      if (tracks) {
        const playlistDetails = await Promise.all(
          tracks.map(async (track: any) => {
            return (
              track?.attributes?.albumName && {
                name: track?.attributes?.albumName,
                artist: track?.attributes?.artistName,
                track_id: track?.id,
                image_url: track?.attributes?.artwork?.url,
              }
            );
          })
        );

        const filteredPlaylistDetails = playlistDetails.filter((i) => i?.name);
        allTracks.push(...filteredPlaylistDetails);
      }
    }

    const sharedPlaylistData = {
      userId: user._id, // Fixed user reference
      playlistName: playlist[0]?.playlistName,
      playlistId: playlist[0]?.playlistId,
      streamingService: "apple", // Ensure correct streaming service is specified
      tracks: allTracks,
      playlistBannerUrl: allTracks[0]?.image_url ?? PlaceholderImg, // Fixed image_url access
    };

    const result: any = await createSharedPlaylist(sharedPlaylistData);

    if (result && result?.data && result.data?._id) {
      const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;

      if (navigator.share) {
        await navigator.share({
          title: "Shared Playlist",
          text: "Check out this playlist I shared!",
          url: url,
        });
      }

      return url;
    }

    return null;
  };

  const sharePlaylists = (playlistId: any) => {
    if (clickedItems.length < 1) {
      return toast.error("Select at least one playlist!");
    }
    switch (appType) {
      case "youtube":
        shareYoutubePlayListTracks(playlistId);
        break;
      case "spotify":
        shareSpotifyPlayListTracks(playlistId);
        break;
      case "apple":
        shareApplePlayListTracks(playlistId);
        break;
      case "library-playlists":
        shareApplePlayListTracks(playlistId);
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <ScreenTitleContainer>
        <ScreenTitle title="Manage Playlist" icon={<BackIcon />} />
      </ScreenTitleContainer>

      <SelectPlaylist
        fetchingPlaylist={isFetchingPlaylist}
        playListArr={playListArr}
        clickedItems={clickedItems}
        setClickedItems={setClickedItems}
      />

      <ActionButton>
        <Buttons onClick={copyPlaylist}>
          <CopyActive />
          <p>Copy playlist</p>
        </Buttons>

        <Buttons onClick={() => sharePlaylists(clickedItems)}>
          <SyncActive />
          <p>Share playlist</p>
        </Buttons>
      </ActionButton>

      {/* All Modals */}

      {/* copy playlist select modal */}
      {(copyModal || syncModal) && !actionCompleted && (
        <>
          <SelectPlaylistBottomModal
            clickedItems={clickedItems}
            setClickedItems={setClickedItems}
            onGoingState={
              copyModal ? setCopyPlaylistOngoing : setSyncPlaylistOngoing
            }
            action={copyModal ? "Copy" : "Sync"}
            closeModal={handleCloseModal}
            playListArr={playListArr}
            actionCompleted={setActionCompleted}
          />
          <BgOverlay />
        </>
      )}

      {/* <DestinationPlatformBottomModal action="Copy" /> */}
      {(syncPlaylistOngoing || copyPlaylistOngoing) && !hideModal && (
        <>
          <ProgressBottomModal
            title={
              copyPlaylistOngoing
                ? "Copy in progress, please wait..."
                : "Sync in progress..."
            }
            bodyText={
              copyPlaylistOngoing
                ? `Copied ${totalCopied} of ${totalTrackss} songs`
                : "Syncing 10 of 100 songs to your playlist"
            }
            closeModal={
              syncPlaylistOngoing
                ? closeSyncPlaylistModal
                : closeCopyPlaylistModal
            }
            actionBtn="Hide"
            callBack={handleHideModal}
            exitBtn="Cancel"
            icontype={copyModal ? "copy" : "sync"}
            progress={progress}
          />
          <BgOverlay />
        </>
      )}

      {actionCompleted && (
        <>
          <CompletedBottomModal
            title="We are done copying your tracks 🤩"
            numberOfTracks={totalTrackss}
            totalNumOfCopiedTracks={totalCopied}
            seeDetails={seeDetails}
            setSeeDetails={setSeeDetails}
            closeModal={() => {
              setActionCompleted(false);
              dispatch(clearCopied());
              dispatch(clearFailedTracks());
              dispatch(clearTotalTracks());
              dispatch(clearTracks());
            }}
          />
          <BgOverlay />
        </>
      )}

      {/* <BgOverlay /> */}
      {/* All Modals ends Here */}
    </Layout>
  );
};

export default ManagePlayListScreen;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 90%;
  bottom: 10%;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px 20px;

  p {
    font-size: 14px;
    color: #008135;
    font-weight: 700;
    font-family: Satoshi;
  }
`;
