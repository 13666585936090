import { Layout } from "components/Layout";
import RecentSearches from "components/RecentSearches";
import { SearchIcon } from "icons";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
// import NotificationImageOne from "../assets/notification-image-one.png";
// import NotificationImageTwo from "../assets/notification-image-two.png";
// import NotificationImageThree from "../assets/notification-image-three.png";
import PlaylistSearchResult from "components/PlaylistSearchResult";
import SongsSearchResult from "components/SongsSearchResult";
import {
  getAllUserYoutubePlaylists,
  GOOGLE_LOCALSTORAGE_VALUES,
  refreshGoogleToken,
} from "services/youtube";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import cookies from "js-cookie";
import { PRIMARY_STREAM_SERVICE } from "contants/primaryStreamingService";
import {
  createPlaylistTracks,
  fetchUserTracks,
  getCurrentUserPlaylists,
  hasTokenExpired,
  LOCALSTORAGE_VALUES,
  refreshToken,
} from "services/spotify";
import { MusicKitContext } from "components/MusicKitController";
import {
  useCreateAiPlaylistMutation,
  useRefreshMutation,
} from "features/auth/authApiSlice";
import { IPlayLists, IYoutubePlayLists } from "@types";
import { useNavigate } from "react-router-dom";

const SearchScreen = () => {
  // const [isPresentlySearching, setIsPresentlySearching] = useState(true);
  const [isPresentlySearching, setIsPresentlySearching] = useState(false);

  const [activeSearchState, setActiveSearchState] = useState("Playlist");
  const [searchQuery, SetSearchQuery] = useState("");
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const context = useContext(MusicKitContext);
  const [applemusicPlaylists, setAppleMusicPlaylists] = useState<any>([]);
  const [refresh] = useRefreshMutation();
  const [appType, setAppType] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const [youtubePlaylists, setYoutubePlaylists] =
    useState<IYoutubePlayLists[]>();
  const [playlists, setPlaylists] = useState<IPlayLists>();
  const [serchedPlaylist, setSearchPlaylist] = useState<any>();
  const [aiGeneratedPlaylist, setAiGeneratedPlaylist] = useState<any>([]);
  const [userSongs, setUserSongs] = useState<any>([]);
  const [searchedSongs, setSearchedSongs] = useState<any>();

  const handleButtonClick = (searchState: string) => {
    setActiveSearchState(searchState);
  };

  const [createPlaylist] = useCreateAiPlaylistMutation();

  const handleCreatePlaylist = async (genre: any) => {
    const data = await createPlaylist({ genre });
    setAiGeneratedPlaylist((prev: any) => [...prev, data]);
  };

  useEffect(() => {
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.SPOTIFY) {
      getSpotifyPlaylists();
    }
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.APPLE_MUSIC) {
      getApplePlaylists();
    }
    if (
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      getYoutubePlaylistt();
    }
  }, []);

  const getYoutubePlaylistt = async () => {
    if (
      (GOOGLE_LOCALSTORAGE_VALUES.refreshToken !== "undefined" ||
        user?.googleRefreshToken) &&
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      const { status, playlists } = await getAllUserYoutubePlaylists();
      console.log(playlists, "---data youtube", status);
      if (status === "success") {
        setAppType("youtube");
        setYoutubePlaylists(status === "success" ? playlists : []);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        refreshGoogleToken(user.googleRefreshToken);
      }
    }
  };

  const getApplePlaylists = async () => {
    console.log("apple section");
    const { data } = await context?.getPlaylists();
    setAppType("apple");
    setAppleMusicPlaylists(data);
    setIsLoading(false);
    console.log(data, "aplle Playlists");
  };

  const getSpotifyPlaylists = async () => {
    const { status, data } = await getCurrentUserPlaylists();
    const res = await getCurrentUserPlaylists();
    console.log(res, "spotfuty section");
    if (res.status === 200) {
      setAppType("spotify");
      setPlaylists(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      await refresh("");
      if (LOCALSTORAGE_VALUES.refreshToken && hasTokenExpired()) {
        refreshToken();
        console.log("getting new spotify token....");
      }
    }
  };

  useEffect(() => {
    handleCreatePlaylist(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    fetchUserSongs();
  }, [searchQuery]);

  const fetchUserSongs = async () => {
    const userSongs = await fetchUserTracks();
    setUserSongs(userSongs);
  };

  // useEffect(() => {
  //   console.log("Updated AI Playlist:", aiGeneratedPlaylist);
  // }, [aiGeneratedPlaylist]);

  const handleSearch = async (event: any) => {
    const query = event.target.value.toLowerCase();
    setIsPresentlySearching(true);
    SetSearchQuery(query);
    if (activeSearchState == "Playlist") {
      filterPlayList(query);
    } else {
      filterSonglist(query);
    }
  };

  const filterSonglist = (query: any) => {
    const filteredSongs = userSongs.filter((song: any) =>
      song.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchedSongs(filteredSongs);
    return filteredSongs;
  };

  const filterPlayList = (query: any) => {
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.SPOTIFY) {
      if (playlists?.items) {
        const filteredPlaylists = playlists.items.filter((playlist) =>
          (playlist as { name: string }).name
            .toLowerCase()
            .includes(query.toLowerCase())
        );
        setSearchPlaylist(filteredPlaylists);
      }
    }
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.APPLE_MUSIC) {
      if (applemusicPlaylists) {
        const filteredPlaylists = applemusicPlaylists.filter((playlist: any) =>
          playlist?.attributes?.name.toLowerCase().includes(query.toLowerCase())
        );
        setSearchPlaylist(filteredPlaylists);
      }
    }
    if (
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      if (youtubePlaylists) {
        const filteredPlaylists = youtubePlaylists.filter((playlist: any) =>
          playlist?.snippet?.title.toLowerCase().includes(query.toLowerCase())
        );
        setSearchPlaylist(filteredPlaylists);
      }
    }
  };

  return (
    <Layout>
      <SearchScreenContainer>
        <h5>Search</h5>
        <SearchInputContainer onSubmit={handleSearch}>
          <SearchButton>
            <SearchIcon />
          </SearchButton>
          <SearchInput
            type="search"
            name="search"
            id="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search for playlist or song"
          />
        </SearchInputContainer>

        {isPresentlySearching ? (
          <SearchContentContainer>
            <SearchContentSelectOptions>
              <StyledButton
                active={activeSearchState === "Playlist"}
                onClick={() => handleButtonClick("Playlist")}
              >
                Playlist
              </StyledButton>
              <StyledButton
                active={activeSearchState === "Songs"}
                onClick={() => handleButtonClick("Songs")}
              >
                Songs
              </StyledButton>
            </SearchContentSelectOptions>
            {activeSearchState === "Playlist" && (
              <PlaylistSearchResult
                playlists={serchedPlaylist}
                aiPlaylists={aiGeneratedPlaylist}
              />
            )}
            {activeSearchState === "Songs" && (
              <SongsSearchResult
                userSongs={searchedSongs}
                aiPlaylists={aiGeneratedPlaylist}
              />
            )}
          </SearchContentContainer>
        ) : (
          <RecentSearches />
        )}
      </SearchScreenContainer>
    </Layout>
  );
};

export default SearchScreen;

const SearchScreenContainer = styled.section`
  position: relative;
  h5 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const SearchButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
`;

const SearchInput = styled.input`
  border: none;
  padding: 12px 16px;
  outline: none;
  width: 100%;
  font-size: 14px;
`;

const SearchContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const SearchContentSelectOptions = styled.div`
  display: flex;
  gap: 8px;
`;

interface StyledButtonProps {
  active: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  padding: 8px 16px;
  border-radius: 32px;
  color: ${({ active }) => (active ? "#FAFAFA" : "#375768")};
  background-color: ${({ active }) => (active ? "#008135" : "transparent")};
  border: solid 1px #5f7f90;
  font-family: Satoshi;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  outline: none;
  cursor: pointer;
`;
