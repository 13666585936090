import { AiIcon } from "icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ViewSearchedPlayListScreen from "screens/ViewSearchedPlaylist";
import SearchedAiCuiratedPlaylist from "screens/ViewSerachedAiScreen";
import styled from "styled-components";

type ViewListProps = {
  playlists: any;
  aiPlaylists: any;
};

const PlaylistSearchResult = ({ playlists, aiPlaylists }: ViewListProps) => {
  const [showPlalistView, setShowPlaylistView] = useState(false);
  const [showAiPlalistView, setShowAiPlaylistView] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState();
  const [selectedAiPlaylist, setSelectedAiPlaylist] = useState();
  const navigate = useNavigate();

  const handlePlaylistDetails = (item: any) => {
    setShowPlaylistView(true);
    setSelectedPlaylist(item);
  };

  const viewAiPlaylist = (data: any) => {
    setShowAiPlaylistView(true);
    setSelectedAiPlaylist(data);
  };

  const handleAiPlaylistDetails = (item: any) => {
    navigate("/view-searched-ai-playlist", { state: { data: item } });
  };

  return (
    <SearchResultContentContainer>
      {showPlalistView ? (
        <div
          style={{
            position: "absolute",
            zIndex: 100,
            top: 0,
            left: 0,
            backgroundColor: "#ffffff",
            width: "100%",
          }}
        >
          <ViewSearchedPlayListScreen
            playlist={selectedPlaylist}
            setShowPlaylist={setShowPlaylistView}
          />
        </div>
      ) : (
        <>
          {playlists &&
            playlists.length > 0 &&
            playlists.map((item: any, index: any) => (
              <>
                <SearchResultContent key={index}>
                  <SearchResultContentLeft
                    onClick={() => handlePlaylistDetails(item)}
                  >
                    <img
                      src={
                        item?.snippet?.thumbnails?.default?.url ??
                        item?.attributes?.artwork?.url ??
                        (Array.isArray(item?.images)
                          ? item.images[0]?.url
                          : undefined)
                      }
                      alt="Search Result Poster"
                    ></img>
                    <div>
                      <h6>
                        {item?.name ??
                          item?.attributes?.name ??
                          item?.snippet?.title}
                      </h6>
                      <p>Tracks: {item?.tracks?.total}</p>
                    </div>
                  </SearchResultContentLeft>
                </SearchResultContent>
              </>
            ))}
          {showAiPlalistView ? (
            <>
              <SearchedAiCuiratedPlaylist />
            </>
          ) : (
            <>
              {aiPlaylists && aiPlaylists.length > 0 ? (
                aiPlaylists.map((item: any) => (
                  <SearchResultContent>
                    <SearchResultContentLeft
                      onClick={() => handleAiPlaylistDetails(item?.data)}
                    >
                      <img src={item?.data?.image} alt="Search Result Poster" />
                      <div>
                        <h6>{item?.data?.playlistName}</h6>
                        <p>Tracks: {item?.data?.tracks?.length || 0}</p>
                      </div>
                      <AiIcon />
                    </SearchResultContentLeft>
                  </SearchResultContent>
                ))
              ) : (
                <p>Loading...</p>
              )}
            </>
          )}
        </>
      )}
    </SearchResultContentContainer>
  );
};

export default PlaylistSearchResult;

const SearchResultContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;

const SearchResultContent = styled.div`
  padding: 12px 16px;
  border: solid 1px #f0f0f0;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 34px;
    height: 34px;
  }
`;

const CopyFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-size: 12px;
    font-weight: 500;
  }
`;

const SearchResultContentLeft = styled.div`
  display: flex;
  gap: 8px;
  // align-items: center;
  h6 {
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #1f1f1f;
  }
  p {
    font-family: Satoshi;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8c8c8c;
  }
`;
