import axios from "axios";
import { selectCurrentUser } from "features/auth/authSlice";
import { setCopied } from "features/tracks/copiedTracksSlice";
import cookies from "js-cookie";
import { useSelector } from "react-redux";
import { parseTitle } from "utils";

const playa_access_token = cookies.get("playa_access_token");

// Map for localStorage keys
export const LOCALSTORAGE_KEYS = {
  accessToken: "youtube_access_token",
  refreshToken: "youtube_refresh_token",
  expireTime: "youtube_token_expire_time",
  timestamp: "youtube_token_timestamp",
};

// Map to retrieve localStorage values
export const GOOGLE_LOCALSTORAGE_VALUES = {
  accessToken: window.localStorage.getItem(LOCALSTORAGE_KEYS.accessToken),
  refreshToken: window.localStorage.getItem(LOCALSTORAGE_KEYS.refreshToken),
  expireTime: window.localStorage.getItem(LOCALSTORAGE_KEYS.expireTime),
  timestamp: window.localStorage.getItem(LOCALSTORAGE_KEYS.timestamp),
};

// const oldRefreshToken = window.localStorage.getItem(LOCALSTORAGE_KEYS.refreshToken) ||
export const refreshGoogleToken = async (oldRefreshToken) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/auth/google/refresh-token?refresh_token=${oldRefreshToken}`
    );
    // console.log(oldRefreshToken, "userr.googleRefreshToken-----");
    console.log(data, "data--google refrehs tojen");
    // Update localStorage values
    localStorage.setItem(LOCALSTORAGE_KEYS.accessToken, data.access_token);

    localStorage.setItem(LOCALSTORAGE_KEYS.timestamp, Date.now());
    // console.log(data, "referehs data");
    window.location.reload(true);
  } catch (e) {
    console.error(e);
  }
};

/**
 * Checks if the amount of time that has elapsed between the timestamp in localStorage
 * and now is greater than the expiration time of 3600 seconds (1 hour).
 * @returns {boolean} Whether or not the access token in localStorage has expired
 */
export const hasGoogleTokenExpired = () => {
  const { accessToken, timestamp, expireTime } = GOOGLE_LOCALSTORAGE_VALUES;
  if (!accessToken || !timestamp) {
    return false;
  }
  const millisecondsElapsed = Date.now() - Number(timestamp);
  return millisecondsElapsed / 1000 > Number(expireTime);
};

/**
 * Handles logic for retrieving the Spotify access token from localStorage
 * or URL query params
 * @returns {string} A Spotify access token
 */
export const getYoutubeAccessToken = async () => {
  if (
    GOOGLE_LOCALSTORAGE_VALUES.accessToken &&
    GOOGLE_LOCALSTORAGE_VALUES.accessToken !== "undefined"
  ) {
    if (hasGoogleTokenExpired()) {
      refreshGoogleToken(GOOGLE_LOCALSTORAGE_VALUES.refreshToken);
    }
    return GOOGLE_LOCALSTORAGE_VALUES.accessToken;
  } else {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/auth/google/token`,
        {
          headers: {
            Authorization: `Bearer ${playa_access_token}`,
          },
        }
      );
      if (data !== null && Object.keys(data).length > 0) {
        localStorage.setItem(LOCALSTORAGE_KEYS.timestamp, Date.now());
        localStorage.setItem(LOCALSTORAGE_KEYS.accessToken, data?.googleToken);
        localStorage.setItem(
          LOCALSTORAGE_KEYS.refreshToken,
          data?.googleRefreshToken
        );
        localStorage.setItem(LOCALSTORAGE_KEYS.expireTime, "3600");
        return data?.googleToken;
      }
    } catch (error) {
      console.log("Error fetching google Tokens ---", error);
    }
  }
};

export const googleAccessToken = getYoutubeAccessToken();

/**
 * Axios global request headers
 * https://github.com/axios/axios#global-axios-defaults
 */
axios.defaults.baseURL = "https://www.googleapis.com/youtube/v3";
axios.defaults.headers["Authorization"] =
  `Bearer ${localStorage.getItem("youtube_access_token")}`;
axios.defaults.headers["Content-Type"] = "application/json";

export const getCurrentUserYoutubePlaylists = async () => {
  return await axios.get(`/playlists?part=snippet&mine=true`);
};

export const getAllUserYoutubePlaylists = async () => {
  try {
    const response = await axios.get(`/playlists?part=snippet&mine=true`);

    if (response.status === 200) {
      // Get the playlists
      const playlists = response.data.items;

      // Add the songCount key to each playlist
      // for (let playlist of playlists) {
      //   const tracksResponse = await getAllTracksInYoutubePlaylist(playlist.id);
      //   if (tracksResponse.status === "success") {
      //     playlist.songCount = tracksResponse.tracks.length;
      //   } else {
      //     console.error("Error fetching tracks for playlist:", playlist.id);
      //     playlist.songCount = 0;
      //   }
      // }

      return { status: "success", playlists: playlists };
    } else {
      console.error("Error fetching playlists:", response.statusText);
      return { status: "error", message: response.statusText };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return { status: "error", message: "an error occured!" };
  }
};

// export const getAllTracksInYoutubePlaylist = async (playlistId) => {
//   try {
//     const songTracks = [];
//     const xTracks = [];
//     let nextPageToken = "";
//     do {
//       const response = await axios.get(
//         `/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=50&pageToken=${nextPageToken}`
//       );

//       // You can now access the playlist items from the response
//       const items = response.data.items;
//       for (let item of items) {
//         const videoId = item.snippet.resourceId.videoId;

//         // Now you can use the video ID to get the video details
//         const videoResponse = await axios.get(
//           `/videos?part=snippet&id=${videoId}`
//         );

//         const videoDetails = videoResponse.data.items[0].snippet;
//         xTracks.push(videoDetails);
//         songTracks.push({
//           id: videoId,
//           songTitle: parseTitle(videoDetails.title, videoDetails.channelTitle)
//             .title, //videoDetails.title,
//           author:
//             parseTitle(videoDetails.title, videoDetails.channelTitle).author ??
//             videoDetails.channelTitle.replace(" - Topic", ""),
//           image: videoDetails.thumbnails.default.url,
//         });
//       }

//       nextPageToken = response.data.nextPageToken;
//     } while (nextPageToken);

//     return { status: "success", tracks: songTracks };
//   } catch (error) {
//     console.error("An error occurred:", error);
//     return { status: "error", message: "an error occured!" };
//   }
// };

export const getAllTracksInYoutubePlaylist = async (playlistId) => {
  try {
    const songTracks = [];
    let nextPageToken = "";

    do {
      // Fetch playlist items (50 max per request)
      const response = await axios.get(
        `/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=50&pageToken=${nextPageToken}`
      );

      const items = response.data.items;
      const videoIds = items
        .map((item) => item?.snippet?.resourceId?.videoId)
        .filter((id) => id); // Filter out undefined or null videoIds

      if (videoIds.length === 0) {
        break; // No more valid videoIds
      }

      // Batch request for video details
      const videoResponse = await axios.get(
        `/videos?part=snippet&id=${videoIds.join(",")}`
      );

      const videoItems = videoResponse?.data?.items || [];

      for (let video of videoItems) {
        const videoDetails = video?.snippet;
        if (!videoDetails) {
          continue;
        }

        songTracks.push({
          id: video.id,
          songTitle: parseTitle(videoDetails?.title, videoDetails?.channelTitle)
            .title,
          author:
            parseTitle(videoDetails?.title, videoDetails?.channelTitle)
              .author ?? videoDetails.channelTitle.replace(" - Topic", ""),
          image: videoDetails.thumbnails.default.url,
        });
      }

      nextPageToken = response.data.nextPageToken;
    } while (nextPageToken);

    return { status: "success", tracks: songTracks };
  } catch (error) {
    console.error("An error occurred:", error);
    return { status: "error", message: "An error occurred!" };
  }
};

export const searchForTrackInYoutube = async (songName) => {
  try {
    const response = await axios.get(
      `/search?part=snippet&maxResults=2&q=${songName}&type=video&videoCategoryId=10`
    );

    if (response.status === 200) {
      return { status: "success", trackDetails: response?.data?.items[0] };
    } else {
      console.error("Error fetching track", response.statusText);
      return { status: "error", message: response.statusText };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return { status: "error", message: "an error occured!" };
  }
};

export const createNewYoutubePlaylist = async (
  playlistName,
  playlistDescription,
  tracks,
  dispatch
) => {
  // Create a new playlist

  try {
    const playlistResponse = await axios.post(
      `/playlists?part=snippet,status`,
      {
        snippet: {
          title: playlistName,
          description: playlistDescription,
        },
        status: {
          privacyStatus: "public",
        },
      }
    );

    const playlistId = playlistResponse.data.id;

    // Add tracks to the new playlist
    for (const track of tracks) {
      await axios.post(`/playlistItems?part=snippet`, {
        snippet: {
          playlistId: playlistId,
          resourceId: {
            kind: "youtube#video",
            videoId: track.videoId,
          },
        },
      });
    }

    console.log(`Playlist created with ID: ${playlistId}`);
    playlistResponse?.data && dispatch(setCopied(1));
    return { status: "success", data: playlistResponse.data };
  } catch (error) {
    console.error(`Failed to create playlist: ${error.message}`);
    return { status: "error", message: "an error occured!" };
  }
};
