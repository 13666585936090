/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ScreenTitleContainer } from "./ProfileScreen";
import PlaceholderImg from "../assets/placeholder.png";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  CopyIcon,
  HomePlayIcon,
  LikeIcon,
  PauseIcon,
  PlayColorIcon,
  PlayIcon,
  ShareMusicIcon,
  SyncedIcon,
} from "icons";
import { Layout } from "components/Layout";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { useFetchSharedPlaylistQuery } from "features/auth/authApiSlice";
import cookies from "js-cookie";
import { LoadingScreen } from "components/common/LoadingScreen";
import { BgOverlay } from "./HomeScreen";
import ProgressBottomModal from "components/Playlist/ProgressBottomModal";
import CompletedBottomModal from "components/Playlist/CompletedBottomModal";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  clearTotalTracks,
  totalTracks,
} from "features/tracks/tracksTotalSlice";
import { clearFailedTracks } from "features/tracks/failedTracksSlice";
import { clearCopied, copied } from "features/tracks/copiedTracksSlice";
import HomeCopyInitiate from "components/Home/CopyInitiate";
import { clearTracks } from "features/tracks/tracksSlice";
import { OutputType } from "@types";
import { PlaylistType } from "types";
import SelectPlaylistBottomModal from "components/ManagePlaylist/DestinationPlatformSharedPlaylistModal";
import { Search } from "components/Search";
import { addTrackToLibrary, deleteTrackToLibrary } from "services/spotify";
import { MusicKitContext } from "components/MusicKitController";

interface Track {
  artistName: string;
  title: string;
  id: string;
  image: {
    url: string;
  };
}

interface LikeButtonProps {
  isLiked: boolean;
}

interface Playlist {
  playlistName: string;
  tracks: Track[];
  image?: string;
}

const AiCuiratedPlaylist = () => {
  // const user = useSelector(selectCurrentUser);
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const params = useParams();
  const { sharedPlaylistId } = params;
  const navigate = useNavigate();
  const [copyPlaylistOngoing, setCopyPlaylistOngoing] =
    useState<boolean>(false);
  const [syncPlaylistOngoing, setSyncPlaylistOngoing] =
    useState<boolean>(false);
  const [actionCompleted, setActionCompleted] = useState<boolean>(false);
  const [initiateCopy, setInitiateCopy] = useState<boolean | null>(null);
  const [handleCopy, setHandleCopy] = useState<boolean | null>(null);
  const [hideModal, setHideModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [playListArr, setPlayListArr] = useState<OutputType>([]);
  const [progress, setProgress] = useState<number>(0);
  const [seeDetails, setSeeDetails] = useState<boolean>(true);
  const totalCopied = useSelector(copied);
  const totalTrackss = useSelector(totalTracks);
  const [clickedItems, setClickedItems] = useState<PlaylistType[]>([]);
  const [selectedTracks, setSelectedTracks] = useState<any[]>([]);
  const [originalSongList, setOriginalSongList] = useState<any>();
  const [filteredSongList, setFilteredSongList] = useState<Playlist>();
  const [songList, setSongList] = useState<any>();
  const [likedTracks, setLikedTracks] = useState<string[]>([]);
  const [currentTrackId, setCurrentTrackId] = useState("");
  const isTrackLiked = (trackId: string) => likedTracks.includes(trackId);

  const [currentAudio, setCurrentAudio] = useState<any>(null);
  const [audioChanged, setAudioChanged] = useState<string>("pause");
  function toggleAudioPlayback(audioUrl: string) {
    if (currentAudio && currentAudio.src === audioUrl) {
      // If the same URL, toggle play/pause
      if (currentAudio.paused) {
        currentAudio.play();
        setAudioChanged("play");
      } else {
        currentAudio.pause();
        setAudioChanged("pause");
      }
    } else {
      // Otherwise, create a new Audio instance
      if (currentAudio) {
        setAudioChanged("pause");
        currentAudio.pause(); // Pause the previous audio
      }
      const newAudio = new Audio(audioUrl);
      setCurrentAudio(newAudio);
      newAudio.play();
      setAudioChanged("play");
    }
  }

  const context = useContext(MusicKitContext);

  const handleHideModals = () => {
    setHideModal(true);
  };
  const location = useLocation();
  const playlists = location.state.data;

  useEffect(() => {
    if (!playlists) {
      navigate("/ai-playlists");
    }
  }, [playlists]);

  const toggleLike = (trackId: string) => {
    setCurrentTrackId(trackId);

    // Check if the track is already liked
    const isTrackLiked = likedTracks.includes(trackId);

    // Get current liked tracks from local storage
    const storedLikedTracks = localStorage.getItem("likedTracks");
    let updatedLikedTracks = storedLikedTracks
      ? JSON.parse(storedLikedTracks)
      : [];

    if (isTrackLiked) {
      // Unlike the track
      deleteTrackToLibrary(trackId);
      updatedLikedTracks = likedTracks.filter((id) => id !== trackId);
    } else {
      // Like the track
      addTrackToLibrary(trackId);
      updatedLikedTracks = [...likedTracks, trackId];
    }

    // Update state and localStorage
    setLikedTracks(updatedLikedTracks);
    localStorage.setItem("likedTracks", JSON.stringify(updatedLikedTracks));
  };

  const addAppleTrackToLibrary = async (trackId: string) => {
    setCurrentTrackId(trackId);

    // Check if the track is already liked
    const isTrackLiked = likedTracks.includes(trackId);

    // Get current liked tracks from local storage
    const storedLikedTracks = localStorage.getItem("likedTracks");
    let updatedLikedTracks = storedLikedTracks
      ? JSON.parse(storedLikedTracks)
      : [];

    if (isTrackLiked) {
      // Unlike the track
      const tracks =
        context && (await context?.removeAppleTrackFromLibrary(trackId));
      if (tracks.status === 202) {
        toast.success("Track added to library");
        updatedLikedTracks = likedTracks.filter((id) => id !== trackId);
      } else {
        toast.error("Something went wrong!");
      }
    }
    // Update state and localStorage
    setLikedTracks(updatedLikedTracks);
    localStorage.setItem("likedTracks", JSON.stringify(updatedLikedTracks));
  };

  const addATrackToLibrary = (trackId: any) => {
    console.log(trackId);
    switch (user?.primaryStreamingService) {
      case "spotify":
        toggleLike(trackId);
        break;
      case "apple":
        addAppleTrackToLibrary(trackId);
        break;
      case "library-playlists":
        addAppleTrackToLibrary(trackId);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (playlists?.tracks) {
      const tracksArr = [];
      for (const item of playlists?.tracks) {
        tracksArr.push({
          artistName: item.artistName,
          title: item.title,
          image: item.image,
        });
      }
      setSelectedTracks(tracksArr);
    }
  }, [playlists]);

  const handleHideModal = () => {
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    setInitiateCopy(false);
    setHandleCopy(false);
  };

  const closeCopyPlaylistModal = () => {
    setCopyPlaylistOngoing(!copyPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
    setHideModal(false);
  };

  const closeSyncPlaylistModal = () => {
    setSyncPlaylistOngoing(!syncPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
  };

  const selectPlaylistToBeCopied = (name: string) => {
    setClickedItems([
      {
        playlistName: name,
        playlistId: "",
        playlistImage: playlists.image,
        selectedTracks: selectedTracks.length > 0 ? selectedTracks : undefined,
      },
    ]);
    setInitiateCopy(true);
  };

  useEffect(() => {
    if (totalCopied !== 0 && totalTrackss !== 0) {
      const percentageComplete = (totalCopied / totalTrackss) * 100;
      setProgress(percentageComplete);
    }
  }, [totalCopied, totalTrackss]);

  function searchTracks(query: string) {
    if (query && query.length > 1) {
      const lowerQuery = query.toLowerCase();

      const filteredTracks = playlists.tracks.filter((track: any) => {
        const trackName = track.title.toLowerCase();
        const artistName = track.artistName.toLowerCase();
        return (
          trackName.includes(lowerQuery) || artistName.includes(lowerQuery)
        );
      });
      setFilteredSongList({ ...playlists, tracks: filteredTracks });
    } else {
      setFilteredSongList(playlists);
    }
  }

  useEffect(() => {
    if (filteredSongList) {
      setFilteredSongList(filteredSongList);
    } else {
      setFilteredSongList(playlists);
    }
  }, [filteredSongList, originalSongList]);

  const shareMusic = () => {
    const url = `https://app.playamusic.io/shared-playlist/${sharedPlaylistId}`;
    if (navigator.share) {
      navigator.share({
        title: "Shared Playlist",
        text: "Check out this playlist I shared!",
        url: url,
      });
    }
  };

  return (
    <Layout>
      <ViewPlaylistScreenContainer>
        <ScreenTitleContainer>
          <Search onChange={searchTracks} placeholder="Search playlist" />
        </ScreenTitleContainer>

        <CurrentSongCard>
          <PlaylistImage>
            <div>
              <HomePlayIcon />
            </div>
            <img src={playlists?.image ?? PlaceholderImg} alt="track cover" />
            <PlaylistOverlay></PlaylistOverlay>
          </PlaylistImage>
          <p>{playlists?.playlistName}</p>
          <span>{playlists?.tracks?.length} songs</span>
        </CurrentSongCard>

        {filteredSongList?.tracks && (
          <ListenerOptionsCard>
            <div
              onClick={() => selectPlaylistToBeCopied(playlists?.playlistName)}
            >
              <CopyIcon />
              <p>Copy</p>
            </div>

            <div onClick={shareMusic}>
              <ShareMusicIcon />
              <p>Share</p>
            </div>
          </ListenerOptionsCard>
        )}

        <SongStackContainer>
          {filteredSongList &&
            filteredSongList?.tracks?.length > 0 &&
            filteredSongList?.tracks.map((item: any, index: any) => {
              return (
                <div key={index}>
                  <div>
                    <img src={item?.image?.url} alt="track cover" />
                    <div>
                      <p>{item?.title}</p>
                      <span>{item?.artistName}</span>
                    </div>
                  </div>
                  <Actions>
                    <div
                      style={{ width: 40 }}
                      onClick={
                        item?.preview_url
                          ? () => toggleAudioPlayback(item?.preview_url)
                          : () => null
                      }
                    >
                      {item?.preview_url &&
                      item?.preview_url !== "no preview" ? (
                        <>
                          {currentAudio &&
                          currentAudio.src === item?.preview_url &&
                          !currentAudio.paused &&
                          audioChanged === "play" ? (
                            <PauseIcon />
                          ) : (
                            <PlayIcon />
                          )}
                        </>
                      ) : (
                        <PlayColorIcon color="#ccc" />
                      )}
                    </div>

                    {userr.primaryStreamingService == "spotify" && (
                      <LikeButton
                        onClick={() => addATrackToLibrary(item?.id)}
                        isLiked={isTrackLiked(item?.id)}
                      >
                        {isTrackLiked(item?.id) ? <LikeIcon /> : <LikeIcon />}
                      </LikeButton>
                    )}
                  </Actions>
                </div>
              );
            })}
        </SongStackContainer>

        {initiateCopy && (
          <>
            <HomeCopyInitiate
              closeModal={() => handleHideModal()}
              callBack={setHandleCopy}
            />
            <BgOverlay />
          </>
        )}

        {handleCopy && !actionCompleted && (
          <>
            <SelectPlaylistBottomModal
              clickedItems={clickedItems}
              setClickedItems={setClickedItems}
              onGoingState={setCopyPlaylistOngoing}
              action="Copy"
              closeModal={handleHideModal}
              source="spotify"
              playListArr={playListArr}
              actionCompleted={setActionCompleted}
            />
            <BgOverlay />
          </>
        )}

        {(syncPlaylistOngoing || copyPlaylistOngoing) && !hideModal && (
          <>
            <ProgressBottomModal
              title={
                copyPlaylistOngoing
                  ? "Copy in progress, please wait..."
                  : "Sync in progress..."
              }
              bodyText={
                copyPlaylistOngoing
                  ? `Copied ${totalCopied} of ${totalTrackss} songs`
                  : "Syncing 10 of 100 songs to your playlist"
              }
              closeModal={
                syncPlaylistOngoing
                  ? closeSyncPlaylistModal
                  : closeCopyPlaylistModal
              }
              actionBtn="Hide"
              callBack={handleHideModals}
              exitBtn="Cancel"
              icontype="copy"
              progress={progress}
            />
            <BgOverlay />
          </>
        )}

        {actionCompleted && (
          <>
            <CompletedBottomModal
              title="We are done copying your tracks 🤩"
              numberOfTracks={totalTrackss}
              totalNumOfCopiedTracks={totalCopied / 2}
              seeDetails={seeDetails}
              setSeeDetails={setSeeDetails}
              closeModal={() => {
                setActionCompleted(false);
                dispatch(clearCopied());
                dispatch(clearFailedTracks());
                dispatch(clearTotalTracks());
                dispatch(clearTracks());
              }}
            />
            <BgOverlay />
          </>
        )}
      </ViewPlaylistScreenContainer>

      <Toaster />
    </Layout>
  );
};

export default AiCuiratedPlaylist;

const ViewPlaylistScreenContainer = styled.div``;

const PlaylistImage = styled.section`
  position: relative;
  height: 100%;
  div {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    z-index: 100;
  }
`;

const PlaylistOverlay = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(6, 22, 30, 1) 60%
  );
  opacity: 0.5;
  border-radius: 16px;
  z-index: 0;
`;

const SongStackContainer = styled.div`
  margin-top: 20px;
  overflow: auto;
  height: 100vh;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    margin: 5px 0;
    padding: 4px 6px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 0px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        border: 0px;
        p {
          font-size: 12px;
          color: #1f1f1f;
          font-weight: 700;
        }
        span {
          font-size: 10px;
          color: #8c8c8c;
          font-weight: 500;
          display: block;
        }
      }
    }
  }
`;

const CurrentSongCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;

  img {
    width: 150px;
    height: 150px;
    border-radius: 22px;
  }
  p {
    font-size: 16px;
    color: #1f1f1f;
    font-weight: 700;
    margin: 5px auto;
  }
  span {
    font-size: 12px;
    color: #8c8c8c;
    display: block;
    font-weight: 500;
  }
`;

const ListenerOptionsCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px;

    p {
      font-size: 12px;
      color: #1f1f1f;
      font-weight: 400;
      padding-left: 8px;
    }
  }
`;

const Actions = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const LikeButton = styled.div<LikeButtonProps>`
  cursor: pointer;
  transition: transform 0.2s ease;

  &:active {
    transform: scale(1.2);
  }

  svg {
    fill: ${({ isLiked }) => (isLiked ? "#008135" : "tansparent")};
    stroke: #008135;
    transition: fill 0.2s ease;
    width: 30px;
    height: 34px;
  }
`;
