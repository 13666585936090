import React, { FC, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cookies from "js-cookie";
import styled from "styled-components";
import { HomeCopyIcon, HomePlayIcon, HomeShareIcon } from "icons";
import PlaylistImage from "assets/playa-play.svg";
import { IPlayListItem } from "@types";
import { getPlaylistTracks } from "services/spotify";
import { useSharePlaylistMutation } from "features/auth/authApiSlice";
import { selectCurrentUser } from "features/auth/authSlice";
import { getPreviewUrl } from "services/conversion";
import { MusicKitContext } from "components/MusicKitController";

interface PlaylistsProps {
  playlists: any;
  clickedItems: any;
  setClickedItems: any;
  initiateCopy: any;
}

const HomeSpotifyPlaylist: FC<PlaylistsProps> = ({
  playlists,
  clickedItems,
  setClickedItems,
  initiateCopy,
}) => {
  const userr = useSelector(selectCurrentUser);
  const context = useContext(MusicKitContext);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const navigate = useNavigate();
  const [sharableUrl, setShareAbleUrl] = useState<string | undefined>(
    undefined
  );

  const [
    createSharedPlaylist,
    { data: newSharedPlaylist, isSuccess, isLoading, error: sharedRoomError },
  ] = useSharePlaylistMutation();

  const spotifyPlayListTracks = async (playlist: any) => {
    try {
      const { data: tracks } = await getPlaylistTracks(playlist?.id);
      const tracksList = tracks.items;

      const playlistDetails = await Promise.all(
        tracksList.map(async (track: any) => {
          return (
            track?.track?.name && {
              name: track?.track?.name,
              url: track?.track?.href,
              isrc: track?.track?.external_ids?.isrc,
              artist: track?.track?.artists[0]?.name,
              track_id: track?.track?.id,
              image_url: track?.track?.album?.images[0]?.url,
              // preview_url: previewUrl ?? "no preview",
            }
          );
        })
      );
      const filteredPlaylistDetails = playlistDetails.filter((i) => i?.name);
      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist?.name,
        playlistId: playlist?.id,
        streamingService: "spotify",
        tracks: filteredPlaylistDetails,
        playlistBannerUrl: playlist?.images ? playlist?.images[0]?.url : "",
      };

      const result: any = await createSharedPlaylist(sharedPlaylistData);
      if (result && result?.data && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        setShareAbleUrl(url);
        console.log(url, "copy url---");
        // Trigger share directly
        if (navigator.share) {
          await navigator.share({
            title: "Shared Playlist",
            text: "Check out this playlist I shared!",
            url: url,
          });
        }
      }
    } catch (error) {
      console.error("Error in spotifyPlayListTracks:", error);
    }
  };

  const selectPlaylistToBeCopied = (name: string, id: string) => {
    setClickedItems([{ playlistName: name, playlistId: id }]);
    initiateCopy(true);
  };

  return (
    <>
      {playlists ? (
        <>
          {playlists?.items?.map((playlist: IPlayListItem, index: number) => (
            <PlaylistWrapper
              key={index}
              backgroundimage={playlist?.images ? playlist?.images[0]?.url : ""}
            >
              <PlaylistOverlay></PlaylistOverlay>
              <PlayIconWrapper
                onClick={() =>
                  navigate(`/listeningroom/${playlist.id}`, {
                    state: { playlistName: playlist?.name },
                  })
                }
              >
                <HomePlayIcon />
              </PlayIconWrapper>
              <PlaylistBottomInfo>
                <PlalistInfo>
                  <PlalistInfoImage>
                    <img src={PlaylistImage} alt="" />
                  </PlalistInfoImage>
                  <PlalistInfoTitle>
                    <h1>{playlist?.name}</h1>
                    <p>
                      {playlist?.tracks?.total} Song{" "}
                      {playlist?.tracks?.total > 1 ? "(s)" : ""}
                    </p>
                  </PlalistInfoTitle>
                </PlalistInfo>

                <PlaylistAction>
                  <PlaylistActionContent
                    onClick={() =>
                      selectPlaylistToBeCopied(playlist?.name, playlist.id)
                    }
                  >
                    <HomeCopyIcon></HomeCopyIcon>
                    <p>Copy playlist</p>
                  </PlaylistActionContent>
                  <PlaylistActionContent
                    onClick={() => spotifyPlayListTracks(playlist)}
                  >
                    <HomeShareIcon></HomeShareIcon>
                    <p>Share playlist</p>
                  </PlaylistActionContent>
                </PlaylistAction>
              </PlaylistBottomInfo>
            </PlaylistWrapper>
          ))}
        </>
      ) : (
        <p>No playlist avaliable</p>
      )}
    </>
  );
};

export default HomeSpotifyPlaylist;

const PlaylistWrapper = styled.section<{ backgroundimage: string }>`
  border-radius: 16px;
  max-width: 100vw;
  background-image: url(${(props) => props.backgroundimage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 444px;
  margin-top: 22px;
  position: relative;
`;

const PlaylistOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(6, 22, 30, 1) 60%
  );
  opacity: 0.5;
  border-radius: 16px;
`;

const PlalistInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  z-index: 1;
  gap: 8px;
`;

const PlalistInfoImage = styled.div`
  width: 50px;
  z-index: 1;

  img {
    width: 50px;
    z-index: 1;
  }
`;

const PlalistInfoTitle = styled.div`
  z-index: 1;
  h1 {
    color: #e3e3e3;
    font-size: 20px;
    font-weight: 700;
    z-index: 1;
  }

  p {
    color: #e3e3e3;
    font-weight: 500;
    font-size: 12px;
  }
`;

const PlaylistBottomInfo = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 20px;
`;

const PlayIconWrapper = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  cursor: pointer;

  svg {
    z-index: 2;
  }
`;

const PlaylistAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  z-index: 10;
`;

const PlaylistActionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  z-index: 10;

  p {
    color: #f5f5f5;
    font-size: 14px;
    font-weight: 700;
    z-index: 10;
  }
`;
